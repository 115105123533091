

.full-section {
    position: relative;
    display: flex;
    height: 100vh;
    align-items: center;
  }

  .full-page {
    height: 90vh;
  }

  .bgvideo {
    position: absolute;
    top: 0;
    opacity: 0.1;
    max-width: 100%;
    mix-blend-mode: screen;
  }

  .bgblack {
   background-color: rgb(0, 0, 0);
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vh;
    height: 100vh;
    filter: brightness(60%);
  }
  
  .text-overlay {
    position: relative;
    color: #fff;
    text-align: center;
    z-index: 1;
    padding: 20px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .text-overlay h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .text-overlay p {
    font-size: 1.2rem;
  }

  .inline {
display: inline;  
}
  
.sorcero {
  background-image: url(../assets/Project_img/sorcero.jpg);
}

/* TextScroll.css */
.scroll-container {
  width: 100%;
  overflow-x: auto; 
  white-space: nowrap; 
  scroll-behavior: smooth; 
  border: 1px solid #ccc; 
  padding: 10px; 
}

.scroll-text {
  font-size: 24px;
  display: inline-block; 
  min-width: 100%; 
}


.section1 {
  height: 350vh; 
}


.wid {
  width: 500px;

}

.qh2 {
  font-size: 7rem;
  text-align: center;
  color: white;
  font-family: Nunito;
}
.qh2s {
  font-size: 3.5rem;
  text-align: center;
  padding-bottom: 3rem;
  color: white;
  font-family: Nunito;
}

.qh3 {
  font-size: 2rem;
  text-align: center;
  padding-bottom: 1rem;
  color: white;
  font-weight: 100;
  font-family: Nunito;
}

.qh4 {
  font-size: 1rem;
  text-align: center;
  padding-bottom: 1rem;
  color: white;
  font-weight: 100;
  font-family: Nunito;
}


.Question1 {
  font-size: 10rem;
  font-family: Nunito;
}


.Question2 {
  font-size: 3rem;
  padding-bottom: 1rem;
  color: #212226;
  font-weight: 100;
  padding-bottom: 1rem;
  font-family: Nunito;
}


.Question3 {
  font-size: 2rem;
  padding-bottom: 1rem;
  color: #212226;
  font-weight: 300;
  font-family: Nunito;
}



.worknum{
  font-size: 16rem;
  font-family: Nunito;
  color: white;
  font-weight: 100;
}

.logo {
  font-size: 2rem;
  font-family: Nunito;
  font-weight: 200;
  text-decoration: none;
  color: white;
}
.logo:hover {
  transition-duration: 500ms;
  color: #5f5f5f;
}

.servicesbg {
  background-color: #E4E4DF;
}

.Servicestxt {
  font-size: 6rem;
  font-family: Nunito;
  font-weight: 200;
  color: white;
  letter-spacing: 0px;
}

.descriptiontxt{
  font-size: 1rem;
  font-family: Nunito;
}

.descriptionwid{
  background-color:#ffffff;
height:250px;
min-width: 400px;
border-radius:12px;
display:flex;
flex-direction:column;
margin: 20px;
}

.descriptionwidmob{
  margin-top: 30px;
  background-color:#ffffff;
border-radius:12px;
display:flex;
flex-direction:column;
}

textarea {
  height: 150px;
}




.image-container {
  position: relative;
  overflow: hidden; 
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); 
  color: white; 
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; 
  transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
  opacity: 1; 
}


.carousel-control-prev {
  width: 15% !important; 
  opacity: 0.3 !important;

}

.carousel-control-next {
  width: 15% !important; 
  opacity: 0.3 !important;
}


.carousel-indicators  {
  opacity: 0.2 !important;
}



.img-fluid-mobile{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  opacity: 0.1;
  width: 100%;
}

.row {
  --bs-gutter-x:0rem !important;
}


.widget-content {
  text-decoration: none;
}


.widget-container{
  display: flex;
  flex-wrap: wrap;
}

.widget {
  background-size: cover;
  position: relative;
  background-position: center;
  height: 350px;
  width: 100%;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;

}

.widget-mobile {
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  max-width: 500px;
  min-height: 300px;
  overflow: hidden;
}


.widget-title {
  font-family: Nunito;
  z-index: 2;
  text-align: start;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  background-color: #212226;
}

.widget-description {
  text-align: start;
  font-family: Nunito;
  padding-right: 30px;
  padding-left: 30px;
  color: rgb(199, 199, 199);
  font-size: 14px;
}

.widget-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.widget:hover .widget-overlay {
  opacity: 1;
}


.widget-overlay-mobile{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}


