/* src/components/Navbar.css */

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding: 10px 20px;
  /* z-index: 1000; */
  transition: top 0.3s ease-in-out;
  background-color: 212226;
}

.navbar-show {
  top: 0;
}

.navbar-hide {
  top: -80px; /* Adjust based on navbar height */
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px; 
  margin: 0;
  list-style: none; 
}

.navbar-links li a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  padding: 5px 10px;
  transition: color 0.3s ease-in-out;
  font-family: Nunito;
  font-weight: 200;
}

.navbar-links li a:hover {
  color: #ccc; 
}

.navbar-drawer-icon {
  color: #fff;
  cursor: pointer;
}

.navsparent {
  position: relative;
  z-index: 999;
}
.navbar-drawer {
  position: absolute;
  top: 30px; 
  right: 20px;
  width: 200px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}

.navbar-drawer-open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.drawer-links {
  list-style: none;
  padding: 8px;
  margin: 0;
  border-radius: 10px;
  background-color: rgb(53, 53, 53);
}

.drawer-links li {
  margin-bottom: 10px;
}

.drawer-links li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.drawer-links li a:hover {
  background-color: #444;
}

.navbar-links li:last-child {
  margin-right: 0; 
}

.navbar-drawer-icon {
  font-size: 24px;
}


@media (max-width: 768px) {
  .navbar-links {
    display: none; 
  }

  .navbar-drawer {
    display: block; 
  }
}
