/* src/App.css */
.App {
    font-family: Arial, sans-serif;
  }
  
  .navbar {
    background-color: #333;
    color: #fff;
    padding: 10px 0;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .navbar-logo {
    font-size: 24px;
    text-decoration: none;
    color: #fff;
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navbar-menu li a {
    text-decoration: none;
    color: #fff;
  }
  
  .hero-section {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  .hero-content {
    text-align: center;
  }
  
  .cta-button {
    background-color: #ff6347;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
  }
  
  /* src/styles/App.css */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

section {
  animation: fadeIn 1s ease-in-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.section {
  position: relative;
  overflow: hidden;
  padding: 50px;
  background-color: #fff;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.section.slide-in {
  animation: slideIn 0.5s forwards;
}

.section.slide-out {
  animation: slideOut 0.5s forwards;
}

.section.hidden {
  display: none;
}